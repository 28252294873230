import React from 'react'
import Text from 'Components/texts/Text'
import styles from './PrivacyPage.module.scss'

export type PrivacyPageProps = {
  title: string
  text: string
}

const PrivacyPage = (props: PrivacyPageProps) => {
  const { title, text } = props

  return (
    <div className={styles.root}>
      <Text className={styles.header}>{title}</Text>
      {/* @ts-ignore */}
      <Text variant="sm" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  )
}

export default PrivacyPage
