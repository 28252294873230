import React from 'react'
import PrivacyPage from './PrivacyPage'

const PrivacyMigrainePage = () => {
  return (
    <PrivacyPage
      title="Migraine: Дневник головной боли > Политика конфиденциальности"
      text={text}
    />
  )
}

const text = `
<p>Компания Good Factory создала приложение «Migraine: Дневник головной боли как приложение Freemium. Этот СЕРВИС предоставляется Good Factory безвозмездно и предназначен для использования как есть.

Эта страница используется для информирования посетителей о нашей политике в отношении сбора, использования и раскрытия личной информации, если кто-то решил воспользоваться нашим Сервисом.

Если вы решили воспользоваться нашим сервисом, то вы соглашаетесь со сбором и использованием информации в соответствии с данной политикой. Личная информация, которую мы собираем, используется для предоставления и улучшения Сервиса. Мы не будем использовать или передавать вашу информацию кому-либо, за исключением случаев, описанных в настоящей Политике конфиденциальности.

Термины, используемые в настоящей Политике конфиденциальности, имеют те же значения, что и в наших Положениях и условиях, которые доступны в Migraine: Дневник головной боли, если иное не определено в настоящей Политике конфиденциальности.

<b>Сбор и использование информации</b>

Для повышения качества обслуживания при использовании нашего Сервиса мы можем попросить вас предоставить определенную информацию, позволяющую идентифицировать личность. Информация, которую мы запрашиваем, сохраняется на вашем устройстве и не собирается нами каким-либо образом.

Приложение использует сторонние сервисы, которые могут собирать информацию, позволяющую идентифицировать вас.

Ссылки на политику конфиденциальности сторонних поставщиков услуг, используемых приложением</p>.
<ul style="margin-bottom: -20px; margin-top: -20px;">
  <li style="margin: 0;"><a href="https://www.google.com/policies/privacy/">Google Play Services</a></li>
  <li style="margin: 0;"><a href="https://support.google.com/admob/answer/6128543?hl=en">AdMob</a></li>
  <li style="margin: 0;"><a href="https://firebase.google.com/policies/analytics">Google Analytics for Firebase</a></li>
  <li style="margin: 0;"><a href="https://firebase.google.com/support/privacy/">Firebase Crashlytics</a></li>
</ul>
<p><b>Данные логирования</b>

Мы хотим сообщить вам, что при использовании нашего Сервиса, в случае ошибки в приложении, мы собираем данные и информацию (с помощью сторонних продуктов) на вашем телефоне, называемые "Данные логирования". Эти Данные логирования могут включать информацию, включающую IP-адрес вашего устройства (Internet Protocol address), название устройства, версия операционной системы, конфигурация приложения в момент использования нашего Сервиса, время и дата вашего использования Сервиса, и другую слежебную информации.

<b>Файлы cookie</b>

Файлы cookie - это файлы с небольшим объемом данных, которые обычно используются в качестве анонимных уникальных идентификаторов. Они отправляются в ваш браузер с веб-сайтов, которые вы посещаете, и сохраняются во внутренней памяти вашего устройства.

Этот Сервис не использует cookie в явном виде. Однако приложение может использовать сторонний код и библиотеки, которые используют cookie для сбора информации и улучшения своих сервисов. У вас есть возможность принять или отклонить эти файлы cookie и узнать, когда они будут отправлены на ваше устройство. Если вы решите отказаться от использования наших файлов cookie, вы, возможно, не сможете использовать некоторые части этого Сервиса.

<b>Поставщики услуг</b>

Мы можем нанимать сторонние компании и частных лиц по следующим причинам:
</p>
<ul style="margin-bottom: -20px; margin-top: -20px;">
  <li style="margin: 0;">Для обслуживания нашего Сервиса;</li>
  <li style="margin: 0;">Для предоставления Сервиса от нашего имени;</li>
  <li style="margin: 0;">Для предоставления услуг, связанных с Сервисом; или</li>
  <li style="margin: 0;">Для помощи в анализе использования нашего Сервиса.</li>
</ul>
<p>Мы хотим проинформировать пользователей этого Сервиса о том, что указанные третьи лица имеют доступ к их личной информации. Причина заключается в выполнении задач, возложенных на них от нашего имени. Однако они обязаны не разглашать и не использовать эту информацию для каких-либо других целей.

<b>Безопасность</b>

Мы ценим ваше доверие к предоставлению нам вашей личной информации, поэтому мы стремимся использовать коммерчески приемлемые средства ее защиты. Но помните, что ни один способ передачи через Интернет или способ электронного хранения не является на 100% безопасным и достоверным, и мы не можем гарантировать его абсолютную безопасность.

<b>Ссылки на другие сайты</b>

Этот сервис может содержать ссылки на другие сайты. Если вы перейдете по ссылке третьей стороны, вы будете перенаправлены на этот сайт. Обратите внимание, что мы не управляем этими внешними сайтами. Поэтому мы настоятельно рекомендуем вам ознакомиться с Политикой конфиденциальности этих веб-сайтов. Мы не контролируем и не несем никакой ответственности за содержание, политику конфиденциальности или действия любых сторонних сайтов или сервисов.

<b>Детская конфиденциальность</b>

Эти сервисы не предназначены для лиц младше 4 лет. Мы сознательно не собираем личную информацию о детях младше 4 лет. В случае, если мы узнаем, что ребенок младше 4 лет предоставил нам личную информацию, мы немедленно удалим ее с наших серверов. Если вы являетесь родителем или опекуном и вам известно, что ваш ребенок предоставил нам личную информацию, пожалуйста, свяжитесь с нами, чтобы мы могли предпринять необходимые действия.

<b>Изменения в настоящей Политике конфиденциальности</b>

Мы можем периодически обновлять нашу Политику конфиденциальности. Поэтому вам рекомендуется периодически просматривать эту страницу на предмет любых изменений. Мы уведом вас о любых изменениях, разместив новую Политику конфиденциальности на этой странице.

Эта политика вступает в силу с 2024-08-20.

<b>Свяжитесь с нами</b>

Если у вас есть какие-либо вопросы или предложения по поводу моей политики конфиденциальности, не стесняйтесь обращаться к нам по адресу <a href="mailto:info@good-factory.ru">info@good-factory.ru</a>.</p>`

export default PrivacyMigrainePage
