import PrivacyMigrainePage from 'Views/privacy/PrivacyMigrainePage'
import React from 'react'
import { graphql } from 'gatsby'

const PrivacyMigraine = () => {
  return <PrivacyMigrainePage />
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default PrivacyMigraine
