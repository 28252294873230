import React, { CSSProperties } from 'react'
import classNames from 'classnames'

export type TextVariants = 'xs' | 'sm-bold' | 'sm' | 'md' | 'md-bold' | 'heading'

export type TextProps = {
  className?: string
  children?: React.ReactNode
  variant?: TextVariants
  Component?: React.ElementType
  style?: CSSProperties
}

const Text = (props: TextProps) => {
  const {
    className = '',
    children = null,
    variant = 'md',
    Component = 'div',
    style,
    ...other
  } = props

  return (
    <Component
      {...other}
      style={style}
      className={classNames(['text', className, variant ? `text-${variant}` : ''])}
    >
      {children}
    </Component>
  )
}

export default Text
